/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import mobileBackground from '../assets/banner/newYear/mob_mt_cover_bg.webp';
import mobileTxtSloganHans from '../assets/banner/newYear/mob_mt_cover_slogan_hans_revised.webp';
import mobileRankingBackground from '../assets/banner/newYear/mob_mt_rb_bg_full.webp';
import mobileConfetti from '../assets/banner/newYear/mob_mt_rb_conffeti.webp';
import mobileTxtSloganEn from '../assets/banner/newYear/mob_mt_title_en.webp';
import mobileTxtSloganHant from '../assets/banner/newYear/mob_mt_title_hant.webp';
import desktopBackground from '../assets/banner/newYear/web_mt_cover_bg.webp';
import rankingBackground from '../assets/banner/newYear/web_mt_rb_bg.webp';
import desktopFans from '../assets/banner/newYear/web_mt_rb_bg_fan.webp';
import desktopConfetti from '../assets/banner/newYear/web_mt_rb_conffeti.webp';

import newTournamentLogoCN from '../assets/newTournament/logo_v_nobackground_tc.png';
import newTournamentLogoEn from '../assets/newTournament/logo_v_nobackground_en.png';
import pokerClubsFly from '../assets/newTournament/POKER_clubs_fly.png';
import pokerDiamondFly from '../assets/newTournament/POKER_diamond_fly.png';
import pokerHeartFly from '../assets/newTournament/POKER_HEART_fly.png';
import pokerSpadeFly from '../assets/newTournament/POKER_SPADE_fly.png';
import coin1 from '../assets/newTournament/coin.png';
import coin2 from '../assets/newTournament/coin2.png';
import coin3 from '../assets/newTournament/coin3.png';
import coin4 from '../assets/newTournament/coin4.png';
import coin5 from '../assets/newTournament/coin5.png';
import coin6 from '../assets/newTournament/coin6.png';
import { type IBanner } from '../interfaces';
import { languageCodes } from '../languages/languages';

import { LoadingBanner } from './LoadingBanner';

import '../styles/Banner.scss';

export default function Banner (
  { isMobileView, showRankings, i18n, t, isChinese }: IBanner): JSX.Element {
  const [loading, setLoading] =
    useState({ titleLogo: false, background: false, rankingBackground: false });

  const isCNTrad: boolean =
    i18n.resolvedLanguage === languageCodes.chineseTraditional;
  const isCNSimp: boolean =
    i18n.resolvedLanguage === languageCodes.chineseSimplified;

  useEffect(() => {
    if (isMobileView) {
      if (isCNTrad) {
        const image = new Image();
        image.onload =
          () => { setLoading(prev => ({ ...prev, titleLogo: true })); };
        image.src = mobileTxtSloganHant;
      }

      if (isCNSimp) {
        const image = new Image();
        image.onload =
          () => { setLoading(prev => ({ ...prev, titleLogo: true })); };
        image.src = mobileTxtSloganHans;
      }

      if (!isChinese) {
        const image = new Image();
        image.onload =
          () => { setLoading(prev => ({ ...prev, titleLogo: true })); };
        image.src = mobileTxtSloganEn;
      }

      const mobileImgStage = new Image();
      mobileImgStage.onload =
        () => { setLoading(prev => ({ ...prev, background: true })); };
      mobileImgStage.src = mobileBackground;

      const mobileRankingBackgroundLoad = new Image();
      mobileRankingBackgroundLoad.onload =
        () => { setLoading(prev => ({ ...prev, rankingBackground: true })); };
      mobileRankingBackgroundLoad.src = mobileRankingBackground;
    };

    if (!isMobileView) {
      if (isCNTrad) {
        const image = new Image();
        image.onload =
          () => { setLoading(prev => ({ ...prev, titleLogo: true })); };
        image.src = newTournamentLogoCN;
      }

      if (isCNSimp) {
        const image = new Image();
        image.onload =
          () => { setLoading(prev => ({ ...prev, titleLogo: true })); };
        image.src = newTournamentLogoCN;
      }

      if (!isChinese) {
        const image = new Image();
        image.onload =
          () => { setLoading(prev => ({ ...prev, titleLogo: true })); };
        image.src = newTournamentLogoCN;
      }

      const desktopImgStage = new Image();
      desktopImgStage.onload =
        () => { setLoading(prev => ({ ...prev, background: true })); };
      desktopImgStage.src = desktopBackground;

      const desktopRankingBackgroundLoad = new Image();
      desktopRankingBackgroundLoad.onload =
        () => { setLoading(prev => ({ ...prev, rankingBackground: true })); };
      desktopRankingBackgroundLoad.src = rankingBackground;
    }
  }, []);

  if (Object.values(loading).every(el => el)) {
    return (
      <div className="banner">
        <ParallaxBanner className="parallax-banner">
          {!showRankings && (
            <>
              <ParallaxBannerLayer
                image={coin1}
                className="coin-1"
                speed={-10}
              />
              <ParallaxBannerLayer
                image={coin2}
                className="coin-2"
                speed={-9}
              />
              <ParallaxBannerLayer
                image={coin3}
                className="coin-3"
                speed={-8}
              />
              <ParallaxBannerLayer
                image={coin4}
                className="coin-4"
                speed={-7}
              />
              <ParallaxBannerLayer
                image={coin5}
                className="coin-5"
                speed={-6}
              />
              <ParallaxBannerLayer
                image={pokerHeartFly}
                className="poker-heart"
                speed={5}
              />
              <ParallaxBannerLayer
                image={coin6}
                className="coin-6"
                speed={6}
              />
              <ParallaxBannerLayer
                image={pokerDiamondFly}
                className="poker-diamond"
                speed={7}
              />
              <ParallaxBannerLayer
                image={pokerClubsFly}
                className="poker-clubs"
                speed={8}
              />
              <ParallaxBannerLayer
                image={pokerSpadeFly}
                className="poker-spade"
                speed={9}
              />
              <ParallaxBannerLayer
                speed={10}
                image={isChinese ? newTournamentLogoCN : newTournamentLogoEn}
                className="new-tournament-logo">
                <div className={`logo-text ${isChinese ? 'cn-logo-text' : ''}`}>
                  <section>
                    <div className="prizes-container">
                      <div className='total-prizes-container'>
                        <p className="total-prize-1">
                          {t('totalPrize')}
                        </p>
                        <p className="total-prize-2">
                          CNY 2,567,520
                        </p>
                      </div>
                    </div>
                  </section>
                  <section>
                    <p className="event-time">{t('eventTime')}</p>
                  </section>
                  <section>
                    <p className="private-table">{t('createYourPrivateTable')}</p>
                  </section>
                </div>
              </ParallaxBannerLayer>
            </>
          )}
          {showRankings && (
            <ParallaxBannerLayer image={desktopFans} className="fans" />
          )}
          {showRankings && (
            <ParallaxBannerLayer image={isMobileView ? mobileConfetti : desktopConfetti} className="confetti" />
          )}
        </ParallaxBanner>
      </div>
    );
  }

  return <LoadingBanner />;
};
