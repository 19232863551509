import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import qIcon from '../assets/newTournament/q_icon.png';

import { type ITournamentRules } from '../interfaces';

export const NewTournamentFAQ = ({ t }: ITournamentRules): JSX.Element => {
  const animationDuration = 300;
  const iconStyle = { color: 'white', height: '48px', width: '48px' };

  const initialQuestionsObj = {
    q1: false,
    q2: false,
    q3: false,
    q4: false,
    q5: false,
    q6: false,
    q7: false,
    q8: false,
    q9: false,
    q10: false,
    q11: false
  };

  const [questionsObj, setQuestionsObj] =
    useState<Record<string, boolean>>(initialQuestionsObj);

  const handleQuestionsObj = (question: string): void => {
    if (!questionsObj[question]) {
      setQuestionsObj({ ...initialQuestionsObj, [question]: true });

      const questionNumber: string = question.split('')[1];
      const element: HTMLElement | null =
        document.getElementById(`nt${questionNumber}`);

      if (element != null) {
        setTimeout(() => {
          element.scrollIntoView(true);
        }, animationDuration);
      }
    }

    if (questionsObj[question]) {
      setQuestionsObj({ ...initialQuestionsObj });
    }
  };

  return (
    <div className="tournament-setting-container">
      <div className={`${questionsObj.q1 ? 'back-shadow' : ''}`}>
        <button id="nt1" onClick={() => { handleQuestionsObj('q1'); } }>
          <div className={`label-container ${questionsObj.q1 ? 'no-border' : ''}`}>
            <aside>
              <img src={qIcon} width="32px" height="32px" alt="q icon" />
              <strong>{t('nFaq1')}</strong>
            </aside>
            {questionsObj.q1
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q1 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('ansNFaq1')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`${questionsObj.q2 ? 'back-shadow' : ''}`}>
        <button id="nt2" onClick={() => { handleQuestionsObj('q2'); } }>
          <div className={`label-container ${questionsObj.q2 ? 'no-border' : ''}`}>
            <aside>
              <img src={qIcon} width="32px" height="32px" alt="q icon" />
              <strong>{t('nFaq2')}</strong>
            </aside>
            {questionsObj.q2
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q2 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('ansNFaq2')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`${questionsObj.q3 ? 'back-shadow' : ''}`}>
        <button id="nt3" onClick={() => { handleQuestionsObj('q3'); } }>
          <div className={`label-container ${questionsObj.q3 ? 'no-border' : ''}`}>
            <aside>
              <img src={qIcon} width="32px" height="32px" alt="q icon" />
              <strong>{t('nFaq3')}</strong>
            </aside>
            {questionsObj.q3
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q3 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('ansNFaq3')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`${questionsObj.q4 ? 'back-shadow' : ''}`}>
        <button id="nt4" onClick={() => { handleQuestionsObj('q4'); } }>
          <div className={`label-container ${questionsObj.q4 ? 'no-border' : ''}`}>
            <aside>
              <img src={qIcon} width="32px" height="32px" alt="q icon" />
              <strong>{t('nFaq4')}</strong>
            </aside>
            {questionsObj.q4
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q4 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('ansNFaq4')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`${questionsObj.q5 ? 'back-shadow' : ''}`}>
        <button id="nt5" onClick={() => { handleQuestionsObj('q5'); } }>
          <div className={`label-container ${questionsObj.q5 ? 'no-border' : ''}`}>
            <aside>
              <img src={qIcon} width="32px" height="32px" alt="q icon" />
              <strong>{t('nFaq5')}</strong>
            </aside>
            {questionsObj.q5
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q5 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('ansNFaq5')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`${questionsObj.q6 ? 'back-shadow' : ''}`}>
        <button id="nt6" onClick={() => { handleQuestionsObj('q6'); } }>
          <div className={`label-container ${questionsObj.q6 ? 'no-border' : ''}`}>
            <aside>
              <img src={qIcon} width="32px" height="32px" alt="q icon" />
              <strong>{t('nFaq6')}</strong>
            </aside>
            {questionsObj.q6
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q6 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('ansNFaq6')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`${questionsObj.q7 ? 'back-shadow' : ''}`}>
        <button id="nt7" onClick={() => { handleQuestionsObj('q7'); } }>
          <div className={`label-container ${questionsObj.q7 ? 'no-border' : ''}`}>
            <aside>
              <img src={qIcon} width="32px" height="32px" alt="q icon" />
              <strong>{t('nFaq7')}</strong>
            </aside>
            {questionsObj.q7
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q7 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('ansNFaq7')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`${questionsObj.q8 ? 'back-shadow' : ''}`}>
        <button id="nt8" onClick={() => { handleQuestionsObj('q8'); } }>
          <div className={`label-container ${questionsObj.q8 ? 'no-border' : ''}`}>
            <aside>
              <img src={qIcon} width="32px" height="32px" alt="q icon" />
              <strong>{t('nFaq8')}</strong>
            </aside>
            {questionsObj.q8
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q8 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('ansNFaq8')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`${questionsObj.q9 ? 'back-shadow' : ''}`}>
        <button id="nt9" onClick={() => { handleQuestionsObj('q9'); } }>
          <div className={`label-container ${questionsObj.q9 ? 'no-border' : ''}`}>
            <aside>
              <img src={qIcon} width="32px" height="32px" alt="q icon" />
              <strong>{t('nFaq9')}</strong>
            </aside>
            {questionsObj.q9
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q9 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('ansNFaq9')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`${questionsObj.q10 ? 'back-shadow' : ''}`}>
        <button id="nt10" onClick={() => { handleQuestionsObj('q10'); } }>
          <div className={`label-container ${questionsObj.q10 ? 'no-border' : ''}`}>
            <aside>
              <img src={qIcon} width="32px" height="32px" alt="q icon" />
              <strong>{t('nFaq10')}</strong>
            </aside>
            {questionsObj.q10
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q10 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('ansNFaq10')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`${questionsObj.q11 ? 'back-shadow' : ''}`}>
        <button id="nt11" onClick={() => { handleQuestionsObj('q11'); } }>
          <div className={`label-container ${questionsObj.q11 ? 'no-border' : ''}`}>
            <aside>
              <img src={qIcon} width="32px" height="32px" alt="q icon" />
              <strong>{t('nFaq11')}</strong>
            </aside>
            {questionsObj.q11
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q11 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('ansNFaq11')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
    </div>
  );
};
