import React from 'react';

import { type IFaqSection } from '../interfaces';
import faqIcon from '../assets/newTournament/faq_icon.png';
import '../styles/Faq.scss';
import { NewTournamentFAQ } from './NewTournamentFAQ';

export const FaqSection =
  ({ t }: IFaqSection): JSX.Element => (
    <section className="faq-container" id="faq">
      <section className="title-container">
        <aside>
          <img src={faqIcon} width="30px" height="30px" alt="FAQ icon" />
        </aside>
        <h2>{t('faq')}</h2>
      </section>
      <div className="faq-content">
        <NewTournamentFAQ t={t} />
      </div>
    </section>
  );
