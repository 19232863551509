import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { type ITournamentRules } from '../interfaces';

export const BettingEventRules = ({ t }: ITournamentRules): JSX.Element => {
  const animationDuration = 300;
  const iconStyle = { color: 'white', height: '48px', width: '48px' };

  const initialQuestionsObj = {
    q1: false,
    q2: false,
    q3: false,
    q4: false,
    q5: false
  };

  const [questionsObj, setQuestionsObj] =
    useState<Record<string, boolean>>(initialQuestionsObj);

  const handleQuestionsObj = (question: string): void => {
    if (!questionsObj[question]) {
      setQuestionsObj({ ...initialQuestionsObj, [question]: true });

      const questionNumber: string = question.split('')[1];
      const element: HTMLElement | null =
        document.getElementById(`tr${questionNumber}`);

      if (element != null) {
        setTimeout(() => {
          element.scrollIntoView(true);
        }, animationDuration);
      }
    }

    if (questionsObj[question]) {
      setQuestionsObj({ ...initialQuestionsObj });
    }
  };

  return (
    <div className="tournament-rules-container">
      <div className={`${questionsObj.q1 ? 'back-shadow' : ''}`}>
        <button id="tr1" onClick={() => { handleQuestionsObj('q1'); } }>
          <div className={`label-container ${questionsObj.q1 ? 'no-border' : ''}`}>
            <strong>{t('eligibility')}</strong>
            {questionsObj.q1
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q1 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('eligibility1')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`${questionsObj.q2 ? 'back-shadow' : ''}`}>
        <button id="tr2" onClick={() => {
          handleQuestionsObj('q2');
        } }>
          <div className={`label-container ${questionsObj.q2 ? 'no-border' : ''}`}>
            <strong>{t('htp')}</strong>
            {questionsObj.q2
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q2 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('htp1')}</li>
              <li>{t('htp2')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`${questionsObj.q3 ? 'back-shadow' : ''}`}>
        <button id="tr3" onClick={() => {
          handleQuestionsObj('q3');
        } }>
          <div className={`label-container ${questionsObj.q3 ? 'no-border' : ''}`}>
            <strong>{t('howToGetPrizes')}</strong>
            {questionsObj.q3
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q3 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('howToGetPrizes1')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`${questionsObj.q4 ? 'back-shadow' : ''}`}>
        <button id="tr4" onClick={() => {
          handleQuestionsObj('q4');
        } }>
          <div className={`label-container ${questionsObj.q4 ? 'no-border' : ''}`}>
            <strong>{t('votingRestrictions')}</strong>
            {questionsObj.q4
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q4 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('vr1')}</li>
              <li>{t('vr2')}</li>
              <li>{t('vr3')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`${questionsObj.q5 ? 'back-shadow' : ''}`}>
        <button id="tr5" onClick={() => {
          handleQuestionsObj('q5');
        } }>
          <div className={`label-container ${questionsObj.q5 ? 'no-border' : ''}`}>
            <strong>{t('bettingPrize')}</strong>
            {questionsObj.q5
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q5 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('betPrize1')}</li>
              <li>{t('betPrize2')}</li>
              <li>{t('betPrize3')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
    </div>
  );
};
