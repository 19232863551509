import React from 'react';

import AGLogo from '../assets/introduction/web_mt_misc_logo_ag.svg';

import leaderboardIcon from '../assets/newTournament/social_leaderboard.png';
import objectsDivider from '../assets/newTournament/OBJECTS.png';

import step2LogoEn from '../assets/newTournament/entry_en.png';
import step2LogoSC from '../assets/newTournament/entry_sc.png';
import step2LogoTC from '../assets/newTournament/entry_tc.png';

import step3LogoEn from '../assets/newTournament/step3_en.png';
import step3LogoSC from '../assets/newTournament/step3_sc.png';
import step3LogoTC from '../assets/newTournament/step3_tc.png';

import { type IIntroduction } from '../interfaces';

import '../styles/Introduction.scss';
import { useTranslation } from 'react-i18next';
import { languageCodes } from '../languages/languages';
export const Introduction = ({ t }: IIntroduction): JSX.Element => {
  const { i18n } = useTranslation();

  const isHans = i18n.resolvedLanguage === languageCodes.chineseSimplified;
  const isHant = i18n.resolvedLanguage === languageCodes.chineseTraditional;
  const isEng = i18n.resolvedLanguage === languageCodes.english;

  const checkStep2Logo = (): string => {
    if (isHans) return step2LogoSC;
    if (isHant) return step2LogoTC;
    return step2LogoEn;
  };

  const checkStep3Logo = (): string => {
    if (isEng) return step3LogoEn;
    if (isHans) return step3LogoSC;

    return step3LogoTC;
  };

  return (
    <section className="introduction-container" id="introduction">
      <div className="intro-title">
        <img src={leaderboardIcon} height="30px" width="30px" alt="leaderboard icon"/>
        <h2 style={{ marginTop: '26px' }}>{t('introduction')}</h2>
      </div>
      <p>{t('introDetails')}</p>
      <div className="how-to-enter-container">
        <div className="how-to-take-part">
          <h2>{t('howToEnter')}</h2>
          <div className="divider-container">
            <img src={objectsDivider} width="50%" height="auto" alt="divider"/>
          </div>
          <section className="steps-container">
            <div>
              <aside>
                <p>{t('step') + ' '}</p><strong className="card-header">1</strong>
              </aside>
              <div className="img-container">
                <img src={AGLogo} width="100%" height="auto" alt="AG Logo"/>
              </div>
              <p className="step-instructions" style={{ marginTop: '40px' }}>{t('step1')}</p>
            </div>
            <div>
              <aside>
                <p>{t('step') + ' '}</p><strong className="card-header">2</strong>
              </aside>
              <div className="img-container">
                <img src={checkStep2Logo()} width="100%" height="auto" alt="step 2 logo"/>
              </div>
              <p className="step-instructions" style={{ marginTop: '40px' }}>{t('step2Details')}</p>
            </div>
            <div>
              <aside>
                <p>{t('step') + ' '}</p><strong className="card-header">3</strong>
              </aside>
              <div className="img-container">
                <img src={checkStep3Logo()} width="100%" height="auto" alt="step 3 logo"/>
              </div>
              <p className="step-instructions" style={{ marginTop: '40px' }}>{t('step3Details')}</p>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};
