import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { type ITournamentRules } from '../interfaces';

export const TournamentRules = ({ t }: ITournamentRules): JSX.Element => {
  const animationDuration = 300;
  const iconStyle = { color: 'white', height: '48px', width: '48px' };

  const initialQuestionsObj = {
    q1: false,
    q2: false,
    q3: false,
    q4: false,
    q5: false
  };

  const [questionsObj, setQuestionsObj] =
    useState<Record<string, boolean>>(initialQuestionsObj);

  const handleQuestionsObj = (question: string): void => {
    if (!questionsObj[question]) {
      setQuestionsObj({ ...initialQuestionsObj, [question]: true });

      const questionNumber: string = question.split('')[1];
      const element: HTMLElement | null =
        document.getElementById(`tr${questionNumber}`);

      /*
       a setTimeout() is necessary because when a question tab expands/collapses
       it takes time and we want that to complete before scrolling into view.
       */

      if (element != null) {
        setTimeout(() => {
          element.scrollIntoView(true);
        }, animationDuration);
      }
    }

    if (questionsObj[question]) {
      setQuestionsObj({ ...initialQuestionsObj });
    }
  };

  return (
    <div className="tournament-rules-container">
      <div className={`${questionsObj.q1 ? 'back-shadow' : ''}`}>
        <button id="tr1" onClick={() => { handleQuestionsObj('q1'); } }>
          <div className={`label-container ${questionsObj.q1 ? 'no-border' : ''}`}>
            <strong>{t('howToParticipate')}</strong>
            {questionsObj.q1
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q1 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('howTo1')}</li>
              <li>{t('howTo2')}</li>
              <li>{t('howTo3')}</li>
              <li>{t('howTo4')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`${questionsObj.q2 ? 'back-shadow' : ''}`}>
        <button id="tr2" onClick={() => {
          handleQuestionsObj('q2');
        } }>
          <div className={`label-container ${questionsObj.q2 ? 'no-border' : ''}`}>
            <strong>{t('howRankings')}</strong>
            {questionsObj.q2
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q2 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('howRank1')}</li>
              <li>{t('howRank2')}</li>
              <li>{t('howRank3')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`${questionsObj.q3 ? 'back-shadow' : ''}`}>
        <button id="tr3" onClick={() => {
          handleQuestionsObj('q3');
        } }>
          <div className={`label-container ${questionsObj.q3 ? 'no-border' : ''}`}>
            <strong>{t('totalProfitMethod')}</strong>
            {questionsObj.q3
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q3 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('totalProfit1')}</li>
              <li>{t('totalProfit2')}</li>
              <li>{t('totalProfit3')}</li>
              <li>{t('totalProfit4')}</li>
              <ol className="example-container">
                <li>{t('totalProfit5')}</li>
              </ol>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`ranking-container ${questionsObj.q4 ? 'back-shadow' : ''}`}>
        <button id="tr4" onClick={() => { handleQuestionsObj('q4'); } }>
          <div className={`label-container ${questionsObj.q4 ? 'no-border' : ''}`}>
            <strong>{t('tableOwnerPrizeCalc')}</strong>
            {questionsObj.q4
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q4 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('tableOwner1')}</li>
              <li>{t('tableOwner2')}</li>
              <li>{t('tableOwner3')}</li>
              <li>{t('tableOwner4')}</li>
              <ol className="example-container">
                <li style={{ listStyleType: 'none' }}>{t('example')}</li>
                <li>{t('tableOwner5')}</li>
                <li>{t('tableOwner6')}</li>
                <li>{t('tableOwner7')}</li>
                <li>{t('tableOwner8')}</li>
              </ol>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`${questionsObj.q5 ? 'back-shadow' : ''}`}>
        <button id="tr5" onClick={() => { handleQuestionsObj('q5'); } }>
          <div className={`label-container ${questionsObj.q5 ? 'no-border' : ''}`}>
            <strong>{t('tableLeaderboardPrize')}</strong>
            {questionsObj.q5
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q5 ? 'auto' : 0}>
          <section className="tr-section-container">
            <table className="prizes-table">
              <tbody>
                <tr>
                  <td>
                    <div className="prize-data">
                      <p>1</p>
                      <aside>
                        <p>CNY</p>
                        <p>288,888</p>
                      </aside>
                    </div>
                  </td>
                  <td>
                    <div className="prize-data">
                      <p>11</p>
                      <aside>
                        <p>CNY</p>
                        <p>18,888</p>
                      </aside>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="prize-data">
                      <p>2</p>
                      <aside>
                        <p>CNY</p>
                        <p>188,888</p>
                      </aside>
                    </div>
                  </td>
                  <td>
                    <div className="prize-data">
                      <p>12</p>
                      <aside>
                        <p>CNY</p>
                        <p>16,888</p>
                      </aside>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="prize-data">
                      <p>3</p>
                      <aside>
                        <p>CNY</p>
                        <p>158,888</p>
                      </aside>
                    </div>
                  </td>
                  <td>
                    <div className="prize-data">
                      <p>13</p>
                      <aside>
                        <p>CNY</p>
                        <p>16,888</p>
                      </aside>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="prize-data">
                      <p>4</p>
                      <aside>
                        <p>CNY</p>
                        <p>128,888</p>
                      </aside>
                    </div>
                  </td>
                  <td>
                    <div className="prize-data">
                      <p>14</p>
                      <aside>
                        <p>CNY</p>
                        <p>12,888</p>
                      </aside>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="prize-data">
                      <p>5</p>
                      <aside>
                        <p>CNY</p>
                        <p>88,888</p>
                      </aside>
                    </div>
                  </td>
                  <td>
                    <div className="prize-data">
                      <p>15</p>
                      <aside>
                        <p>CNY</p>
                        <p>12,888</p>
                      </aside>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="prize-data">
                      <p>6</p>
                      <aside>
                        <p>CNY</p>
                        <p>68,888</p>
                      </aside>
                    </div>
                  </td>
                  <td>
                    <div className="prize-data">
                      <p>16</p>
                      <aside>
                        <p>CNY</p>
                        <p>8,888</p>
                      </aside>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="prize-data">
                      <p>7</p>
                      <aside>
                        <p>CNY</p>
                        <p>58,888</p>
                      </aside>
                    </div>
                  </td>
                  <td>
                    <div className="prize-data">
                      <p>17</p>
                      <aside>
                        <p>CNY</p>
                        <p>8,888</p>
                      </aside>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="prize-data">
                      <p>8</p>
                      <aside>
                        <p>CNY</p>
                        <p>38,888</p>
                      </aside>
                    </div>
                  </td>
                  <td>
                    <div className="prize-data">
                      <p>18</p>
                      <aside>
                        <p>CNY</p>
                        <p>5,888</p>
                      </aside>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="prize-data">
                      <p>9</p>
                      <aside>
                        <p>CNY</p>
                        <p>28,888</p>
                      </aside>
                    </div>
                  </td>
                  <td>
                    <div className="prize-data">
                      <p>19</p>
                      <aside>
                        <p>CNY</p>
                        <p>5,888</p>
                      </aside>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="prize-data">
                      <p>10</p>
                      <aside>
                        <p>CNY</p>
                        <p>18,888</p>
                      </aside>
                    </div>
                  </td>
                  <td>
                    <div className="prize-data">
                      <p>20</p>
                      <aside>
                        <p>CNY</p>
                        <p>5,888</p>
                      </aside>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </AnimateHeight>
      </div>
    </div>
  );
};
