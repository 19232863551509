import React, { useState } from 'react';
import bookmarkStar from '../assets/newTournament/bookmark_star.png';

import { type IDetails } from '../interfaces';
import tabUnderline from '../assets/newTournament/tab_underline.png';

import { TournamentRules } from './TournamentRules';

import '../styles/DetailsSection.scss';
import { BettingEventRules } from './BettingEventRules';

export const Details = ({ t }: IDetails): JSX.Element => {
  const TABS = {
    tournamentRules: 'TOURNAMENT_RULES',
    bettingEventRules: 'BETTING_EVENT_RULES',
    termsAndConditions: 'TERMS_AND_CONDITIONS'
  };
  const [tab, setTab] = useState<string>(TABS.tournamentRules);
  return (
    <section className="details-container" id="details">
      <section className="title-container">
        <aside>
          <img src={bookmarkStar} width="24px" height="30px" alt="bookmark star"/>
        </aside>
        <h2>{t('details')}</h2>
      </section>
      <div className="details-content">
        <div className="details-section-nav-container">
          <button className={tab === TABS.tournamentRules ? 'disabled-btn' : 'btn-highlight'}
            onClick={() => { setTab(TABS.tournamentRules); }}>
            <strong>{t('tournamentRules')}</strong>
          </button>
          <button className={tab === TABS.bettingEventRules ? 'disabled-btn' : 'btn-highlight'}
            onClick={() => { setTab(TABS.bettingEventRules); }}
          >
            <strong>{t('championshipRules')}</strong>
          </button>
          <button className={tab === TABS.termsAndConditions ? 'disabled-btn' : 'btn-highlight' }
            onClick={() => { setTab(TABS.termsAndConditions); }}>
            <strong>{t('tc')}</strong>
          </button>
        </div>
        <div style={{ marginTop: '-12px' }}>
          <img src={tabUnderline} width="100%" height="4px" alt="tab underline" />
        </div>
        {tab === TABS.tournamentRules && <TournamentRules t={t}/>}
        {tab === TABS.bettingEventRules && <BettingEventRules t={t} />}
        {tab === TABS.termsAndConditions && (
          <div className={'terms-conditions-container back-shadow'}>
            <ol>
              <li>{t('ntc1')}</li>
              <li>{t('ntc2')}</li>
              <li>{t('ntc3')}</li>
              <li>{t('ntc4')}</li>
              <li>{t('ntc5')}</li>
              <li>{t('ntc6')}</li>
              <li>{t('ntc7')}</li>
              <li>{t('ntc8')}</li>
            </ol>
          </div>
        )}
      </div>
    </section>
  );
};
